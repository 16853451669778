import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import route from "./route";
import "./App.scss";
import { ican } from "./api";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

class App extends Component {
  awake = async () => {
    const res = await ican.get("/");
    console.log(res.data);
  };
  componentDidMount() {
    this.awake();
  }

  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          <Switch>
            {route.map((route, idx) => {
              return route.component ? (
                <Route
                  key={idx}
                  path={route.path}
                  exact={route.exact}
                  name={route.name}
                  render={(props) => <route.component {...props} />}
                />
              ) : null;
            })}
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    );
  }
}

export default App;
