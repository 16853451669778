import axios from "axios";

const ican = axios.create({
  baseURL:
    process.env.NODE_ENV === "development"
      ? "http://localhost:5000/api"
      : "https://icanezd-api.onrender.com/api",
});

export { ican };
