import { ican } from "../api";

export default (token = null) => {
  console.log(ican);
  if (token) {
    ican.defaults.headers.common.authorization = token;
  } else {
    delete ican.defaults.headers.common.authorization;
  }
};
