import React from "react";

const DefaultLayout = React.lazy(() => import("./containers/DefaultLayout"));

// Pages
const Login = React.lazy(() => import("./views/Pages/Login"));
const Register = React.lazy(() => import("./views/Pages/Register"));
const ForgotPassword = React.lazy(() =>
  import("./views/Pages/ForgotPassword/ForgotPassword")
);
const ResetMain = React.lazy(() =>
  import("./views/Pages/ForgotPassword/ResetMain")
);

const route = [
  { path: "/register", exact: true, name: "Dashboard", component: Register },
  { path: "/login", exact: true, name: "Login", component: Login },
  {
    path: "/forgotpassword",
    exact: true,
    name: "ForgotPassword",
    component: ForgotPassword,
  },
  {
    path: "/passwordreset",
    exact: true,
    name: "ResetPassword",
    component: ResetMain,
  },
  { path: "/", component: DefaultLayout, name: "Home" },
];

export default route;
